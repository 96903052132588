import { Link } from "gatsby"
import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Step4 = () => (
  <StaticQuery
    query={graphql`
      query {
        allWpPage(filter: {slug: {eq: "calculator"}}) {
          edges {
            node {
              TeamlanceCalculator {
                calculatorSteps {
                  emailPlaceholder
                  fieldGroupName
                  namePlaceholder
                  phonePlaceholder
                  privacyText
                  steps {
                    buttonText
                    description
                    fieldGroupName
                    heading
                    iconSvg
                    icon {
                      sourceUrl
                    }
                    options {
                      optionTitle
                    }
                    percentComplete
                    title
                  }
                }
                designSettings {
                  buttonBg
                  leftMenuBg
                  leftTextColor
                  logo {
                    sourceUrl
                  }
                  logoSvg
                  rightTextColor
                }
              }
              slug
            }
          }
        }
      }
    `}
    render={data => {
        return(
          data && data.allWpPage &&
          data.allWpPage.edges &&
          data.allWpPage.edges[0] &&
          data.allWpPage.edges[0].node &&
          data.allWpPage.edges[0].node.TeamlanceCalculator &&
          data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps &&
          data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps&&
          data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps.length>0&&
          <div class="mainContentWrapper">
            <div
              onclick="location.href = 'calculator-step-3.html'; return false;"
              class="back d-flex align-items-center"
            >
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 8.75L1.75 5L5.5 1.25"
                  stroke="#C4C4C4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="mb-0 ml-3">Back</p>
            </div>
            <div class="row dashboardCardRow">
              <div class="col-md-10 col-lg-8 d-flex align-items-center ">
                <div class="dashboard-content">
                  <figure dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[3].iconSvg}} class="my-5"></figure>
                  <h1>{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[3].heading}</h1>
                  <p class="mb-5">{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[3].description}</p>
                  <form>
                    <div class="form-check-inline radio-form">
                      <label class="customradio mb-0">
                        <span class="radiotextsty">{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[3].options[0].optionTitle}</span>
                        <input type="radio" checked="checked" name="radio" />
                        <span class="checkmark"></span>
                      </label>{" "}
                             
                    </div>
                    <div class="form-check-inline radio-form">
                      <label class="customradio">
                        <span class="radiotextsty">{data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[3].options[1].optionTitle}</span>
                        <input type="radio" name="radio" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </form>
                  <div class="text-left">
                    <Link to="/calculator/calculator-step-5">
                      {" "}
                      <button
                        //   onclick="location.href = 'calculator-step-5.html'; return false;"
                        data-abc="true"
                        class="btn btn-default waves-effect waves-light next-step"
                      >
                        {data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[3].buttonText}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }}
  />
  
)

export default Step4
