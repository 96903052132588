import React from 'react';
import Layout from "../../components/calculator/layout";
import SEO from "../../components/seo";

import Sidebar from "../../components/calculator/sidebar";
import Step4 from "../../components/calculator/step-4";

const CalculatorStep4 = (props) => (
    <Layout>
        <SEO title="Calculator Step 4"/>
        <div id="wrapper" class="d-flex justify-content-between">
                  <Sidebar {...props}/> 
            <Step4/>
        </div>
    </Layout>
)

export default CalculatorStep4;

